/*BUTTONS*/

button {
  outline: unset !important;
  outline: unset -webkit-focus-ring-color !important;
}

//FIRST LEVEL OF BTN - BLUE
.btn-first {
  color: #ffffff;
  background-color: #0058ff;

  font-size: 14px;
  border: 1px solid transparent;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 4px;
  white-space: nowrap;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-first:hover {
  color: #ffffff;
  background-color: #0047cc;
}

.btn-first:focus {
  color: #ffffff;
  background-color: #0047cc;
  box-shadow: 0 0 0 2px rgb(168 198 253);
}

.btn-first:active {
  color: #80acff;
  background-color: #0047cc;
}

.btn-first:disabled {
  color: #ffffff;
  background-color: #a8c6fd;
  box-shadow: unset;
}

//FIRST LEVEL OF BTN - GREEN
.btn-first-green {
  color: #ffffff;
  background-color: #21d59b;
  font-size: 14px;
  border: 1px solid transparent;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 4px;
  white-space: nowrap;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-first-green:hover {
  color: #ffffff;
  background-color: #18a377;
}

.btn-first-green:focus {
  color: #ffffff;
  background-color: #18a377;
  box-shadow: 0 0 0 2px rgb(143 234 205);
}

.btn-first-green:active {
  color: #8feacd;
  background-color: #18a377;
}

.btn-first-green:disabled {
  color: #ffffff;
  background-color: #8feacd;
  box-shadow: unset;
}

//FIRST LEVEL OF BTN - RED
.btn-first-red {
  color: #ffffff;
  background-color: #f0142f;
  font-size: 14px;
  border: 1px solid transparent;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 4px;
  white-space: nowrap;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-first-red:hover {
  color: #ffffff;
  background-color: #bd1526;
  cursor: pointer;
}

.btn-first-red:focus {
  color: #ffffff;
  background-color: #bd1526;
  box-shadow: 0 0 0 2px rgb(240 146 156);
}

.btn-first-red:active {
  color: #f0929c;
  background-color: #bd1526;
}

.btn-first-red:disabled {
  color: #ffffff;
  background-color: #f0929c;
  box-shadow: unset;
}

//FIRST LEVEL OF BTN - ORANGE
.btn-first-orange {
  color: #ffffff;
  background-color: #f99600;
  font-size: 14px;
  border: 1px solid transparent;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 4px;
  white-space: nowrap;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-first-orange:hover {
  color: #ffffff;
  background-color: #c77700;
}

.btn-first-orange:focus {
  color: #ffffff;
  background-color: #c77700;
  box-shadow: 0 0 0 2px rgb(250 210 167);
}

.btn-first-orange:active {
  color: #fad2a7;
  background-color: #c7771f;
}

.btn-first-orange:disabled {
  color: #ffffff;
  background-color: #fcca7f;
  box-shadow: unset;
}

//FIRST LEVEL OF INVERTED BTN - GREEN
.btn-first-green-inverted {
  color: #21d59b;
  background-color: #ffffff;
  font-size: 14px;
  border: 1px solid transparent;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 4px;
  white-space: nowrap;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-first-green-inverted:hover {
  color: #ffffff;
  background-color: #18a377;
  box-shadow: 0 0 0 2px rgb(24 163 119 / 50%);
}

.btn-first-green-inverted:focus {
  color: #8feacd;
  background-color: #18a377;
}

.btn-first-green-inverted:disabled {
  color: #21d59b;
  background-color: #8feacd;
  box-shadow: unset;
}

//FIRST LEVEL OF INVERTED BTN - RED
.btn-first-red-inverted {
  color: #f0142f;
  background-color: #ffffff;
  font-size: 14px;
  border: 1px solid transparent;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 4px;
  white-space: nowrap;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-first-red-inverted:hover {
  color: #ffffff;
  background-color: #bd1526;
  box-shadow: 0 0 0 2px rgb(189 21 38 / 50%);
}

.btn-first-red-inverted:focus {
  color: #f0929c;
  background-color: #bd1526;
}

.btn-first-red-inverted:disabled {
  color: #f01b2f;
  background-color: #f0929c;
  box-shadow: unset;
}

//FIRST LEVEL OF INVERTED BTN - ORANGE
.btn-first-orange-inverted {
  color: #f99600;
  background-color: #ffffff;
  font-size: 14px;
  border: 1px solid transparent;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 4px;
  white-space: nowrap;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-first-orange-inverted:hover {
  color: #ffffff;
  background-color: #c77700;
  box-shadow: 0 0 0 2px rgb(255 255 255 / 50%);
}

.btn-first-orange-inverted:focus {
  color: #fac58c;
  background-color: #c7771f;
}

.btn-first-orange-inverted:disabled {
  color: #c7771f;
  background-color: #f9c388;
  //border: 1px solid #F0929C;
  box-shadow: unset;
}

//SECOND LEVEL OF BTN - LIGHT BLUE
.btn-second {
  color: #0058ff !important;
  background-color: #f2f7ff;
  font-size: 14px;
  border: 1px solid transparent;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 4px;
  white-space: nowrap;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-second:hover {
  color: #ffffff;
  background-color: #e5effe;
}

.btn-second:focus {
  background-color: #e5effe;
  box-shadow: 0 0 0 2px rgb(168 198 254);
}

.btn-second:active {
  color: #a8c6fd;
  background-color: #e5effe;
}

.btn-second:disabled {
  color: #a8c6fd;
  background-color: #ffffff;
  border: 1px solid #f2f7ff;
  box-shadow: unset;
}

//THIRD LEVEL OF BTN - OUTLINE
.btn-third {
  color: #5a607f;
  background-color: #ffffff;
  font-size: 14px;
  border: 1px solid #d7dbec;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 4px;
  white-space: nowrap;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-third:hover {
  color: #5a607f;
  background-color: #ffffff;
  border: 1px solid #7e84a3;
}

.btn-third:focus {
  background-color: #ffffff;
  border: 1px solid #7e84a3;
  box-shadow: 0 0 0 2px rgb(209 213 219);
}

.btn-third:active {
  color: #d7dbec;
  background-color: #ffffff;
  border: 1px solid #7e84a3;
}

.btn-third:disabled {
  color: #d7dbec;
  background-color: #ffffff;
  border: 1px solid #d7dbec;
  box-shadow: unset;
}

//THIRD LEVEL OF BTN - OUTLINE
.btn-third-grey {
  color: black;
  background-color: #E6E6E6;
  font-size: 14px;
  border: 1px solid black;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  //padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 4px;
  white-space: nowrap;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;
  padding: 0.5rem 3rem;
}

.btn-third-grey:hover {
  //background-color: #b3b3b3;
  border: 1px solid black;
}

.btn-third-grey:focus {
  //background-color: #5a607f;
  border: 1px solid black;
  box-shadow: 0 0 0 2px rgb(209 213 219);
}

.btn-third-grey:active {
  //background-color: #d7dbec;
  border: 1px solid black;
}

.btn-third-grey:disabled {
  //background-color: #d7dbec;
  border: 1px solid black;
  box-shadow: unset;
}

//THIRD LEVEL OF BTN - OUTLINE GREEN
.btn-third-green {
  color: #21d59b;
  background-color: #ffffff;
  font-size: 14px;
  border: 1px solid #21d59b;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 4px;
  white-space: nowrap;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-third-green:hover {
  color: #18a377;
  background-color: #ffffff;
  border: 1px solid #18a377;
}

.btn-third-green:focus {
  color: #18a377;
  background-color: #ffffff;
  border: 1px solid #18a377;
  box-shadow: 0 0 0 2px rgb(232 251 245);
}

.btn-third-green:active {
  color: #8feacd;
  background-color: #ffffff;
  border: 1px solid #21d59b;
}

.btn-third-green:disabled {
  color: #8feacd;
  background-color: #ffffff;
  border: 1px solid #8feacd;
  box-shadow: unset;
}

//THIRD LEVEL OF OUTLINE INVERTED BTN - GREEN
.btn-third-green-inverted {
  color: #ffffff;
  background-color: #21d59b;
  font-size: 14px;
  border: 1px solid #ffffff;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 4px;
  white-space: nowrap;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-third-green-inverted:hover {
  color: #ffffff;
  background-color: #21d59b;
  border: 1px solid #ffffff;
  box-shadow: 0 0 0 2px rgb(33 213 155 / 50%);
}

.btn-third-green-inverted:focus {
  color: #e8fbf5;
  background-color: #8feacd;
  border: 1px solid #8feacd;
}

.btn-third-green-inverted:disabled {
  color: #8feacd;
  background-color: #21d59b;
  border: 1px solid #ffffff;
  box-shadow: unset;
}

//THIRD LEVEL OF OUTLINE INVERTED BTN - RED
.btn-third-red-inverted {
  color: #ffffff;
  background-color: #f0142f;
  font-size: 14px;
  border: 1px solid #ffffff;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 4px;
  white-space: nowrap;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-third-red-inverted:hover {
  color: #ffffff;
  background-color: #f0142f;
  border: 1px solid #ffffff;
  box-shadow: 0 0 0 2px rgb(240 20 47 / 50%);
}

.btn-third-red-inverted:focus {
  color: #f0929c;
  background-color: #f0142f;
  border: 1px solid #ffffff;
}
.btn-third-red-inverted:disabled {
  color: #fee7e9;
  background-color: #f0929c;
  border: 1px solid #f0929c;
  box-shadow: unset;
}

.btn-white {
  background-color: #ffffff;
  color: #131523;
  font-size: 14px;
  font-weight: 600 !important;
  border-radius: 4px;
}

.btn-white:hover {
  background-color: #0058ff;
  color: #ffffff;
}

.btn-white-selected {
  background-color: #0058ff;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600 !important;
  border-radius: 4px;
}

.btn-add-products {
  color: #ffffff;
  background-color: #f1f5ff;
  border: none;
  white-space: nowrap;
}

.login-btn {
  border-radius: 4px;
  color: #ffffff !important;
}

.login-btn i {
  color: #ffffff !important;
}

.btn-input {
  font-size: 14px;
  font-weight: 600 !important;
  color: #0058ff;
  background-color: #f3f3f4;
  border: #f3f3f4;
  border-radius: 0 4px 4px 0;
  padding-top: 8px;
  padding-bottom: 9px;
  white-space: nowrap;
  height: 100%;
}
.btn-input:hover {
  color: #0058ff;
  background-color: #ffffff;
  box-shadow: 0 0 0 2px rgb(0 88 255 / 50%);
}

/*CIRCLE BUTTONS FOR MODALS */

.circle-blue {
  display: inline-block;
  height: 20px;
  width: 20px;
  background-color: #0058ff;
  color: #ffffff;
  border-radius: 50%;
}
.circle-orange{
  width: 20px;
  height: 20px;
  color: #F99600;
  background-color: #FFF4E5;
}

.circle-red {
  width: 20px;
  height: 20px;
  background-color: #f0142f;
  color: #ffffff;
}

.circle-green {
  width: 20px;
  height: 20px;
  background-color: #21d59b;
  color: #ffffff;
}

.btn-trash {
  background-color: #ffffff;
  color: #f0142f;
  border-radius: 50%;
  border: 1px solid transparent;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-trash:hover {
  background-color: #fee7e9;
  border-radius: 50%;
  box-shadow: 0 0 0 2px rgb(240 20 47 / 50%);
  border: 1px solid #f0142f;
}

.btn-edit {
  color: #5a607f;
  background-color: #ffffff;
  border: 1px solid transparent;
  border-radius: 50%;
  white-space: nowrap;
  position: relative;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-edit:hover {
  color: #0058ff;
  background-color: #ffffff;
  border: 1px solid #0058ff;
  box-shadow: 0 0 0 2px rgb(0 88 255 / 50%);
  border-radius: 50%;
}

.btn-search {
  border: 1px solid #f3f3f4;
  background-color: #f3f3f4;
  border-radius: 0 4px 4px 0;
  color: #0058ff;
  font-size: 1rem;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-search:hover {
  border: 1px solid #f3f3f4;
  box-shadow: 0 0 0 2px rgb(168 198 253 / 50%);
  background-color: #f3f3f4 !important;
}

/*CENTERING VERTICALLY SPINNER TO BE NEXT TO THE LABEL*/
.spinner-border-sm {
  margin-bottom: 2px;
}

.btn-ean {
  font-size: 14px;
  font-weight: 600 !important;
  color: #0058ff;
  background-color: #f3f3f4;
  border: 1px solid #f3f3f4;
  white-space: nowrap;
  height: 100%;
}

.right {
  margin-right: 0 !important;
  margin-top: 4px;
  margin-left: 8px;
}

//PROCESS STATUS LABELS
.process-status-recieved {
  color: #0058ff;
  font-size: 12px;
  font-weight: 700 !important;
  background-color: #0058ff19;
  padding: 4px 10px;
  border-radius: 4px;
  border: 1px solid #0058ff19;
}

.process-status-picking {
  color: #8950fc;
  font-size: 12px;
  font-weight: 700 !important;
  background-color: #f3edff;
  padding: 4px 10px;
  border-radius: 4px;
  border: 1px solid #0058ff19;
}

.process-status-packing {
  color: #6979f8;
  font-size: 12px;
  font-weight: 700 !important;
  background-color: #ebedff;
  padding: 4px 10px;
  border-radius: 4px;
  border: 1px solid #0058ff19;
}

.process-status-packed {
  color: #57b8ff;
  font-size: 12px;
  font-weight: 700 !important;
  background-color: #eef8ff;
  padding: 4px 10px;
  border-radius: 4px;
  border: 1px solid #0058ff1a;
}

.process-status-dispatched {
  color: #f99600;
  font-size: 12px;
  font-weight: 700 !important;
  background-color: #fff4e5;
  padding: 4px 10px;
  border-radius: 4px;
  border: 1px solid #57b8ff1a;
}

.process-status-delivered {
  color: #21d59b;
  font-size: 12px;
  font-weight: 700 !important;
  background-color: #21d59b1a;
  padding: 4px 10px;
  border-radius: 4px;
  border: 1px solid #21d59b1a;
}

.process-status-returned {
  color: #f0142f;
  font-size: 12px;
  font-weight: 700 !important;
  background-color: #f0142f1a;
  padding: 4px 10px;
  border-radius: 4px;
  border: 1px solid #f0142f1a;
}

.process-status-picking_error {
  color: #e03997;
  font-size: 12px;
  font-weight: 700 !important;
  background-color: #ffe6f4;
  padding: 4px 10px;
  border-radius: 4px;
  border: 1px solid #f0142f1a;
}

.process-status-cancelled {
  color: #92929d;
  font-size: 12px;
  font-weight: 700 !important;
  background-color: #92929d1a;
  padding: 4px 10px;
  border-radius: 4px;
  border: 1px solid #92929d1a;
}

//.carrier-label-Zásilkovna {
//  background-color: rgba(255, 0, 0, 0.5);
//  font-size: 12px;
//  font-weight: 700 !important;
//  padding: 4px 10px;
//  border-radius: 4px;
//  border: 1px solid rgba(255, 0, 0, 0.5);
//}
//
//.carrier-label-PPL {
//  background-color: rgba(70, 140, 255, 0.5);
//  font-size: 12px;
//  font-weight: 700 !important;
//  padding: 4px 10px;
//  border-radius: 4px;
//  border: 1px solid rgba(70, 140, 255, 0.5);
//}
//
//.carrier-label-GLS {
//  background-color: rgba(222, 140, 40, 0.5);
//  font-size: 12px;
//  font-weight: 700 !important;
//  padding: 4px 10px;
//  border-radius: 4px;
//  border: 1px solid rgba(222, 140, 40, 0.5);
//}
//
//.carrier-label-Osobní.odběr {
//  background-color: rgba(0, 255, 255, 0.5);
//  font-size: 12px;
//  font-weight: 700 !important;
//  padding: 4px 10px;
//  border-radius: 4px;
//  border: 1px solid rgba(0, 255, 255, 0.5);
//}
//
//.carrier-label-DPD {
//  background-color: rgba(136, 8, 46, 0.5);
//  font-size: 12px;
//  font-weight: 700 !important;
//  padding: 4px 10px;
//  border-radius: 4px;
//  border: 1px solid rgba(136, 8, 46, 0.5);
//}
//
//.carrier-label-Česká.pošta {
//  background-color: rgba(0, 0, 255, 0.5);
//  font-size: 12px;
//  font-weight: 700 !important;
//  padding: 4px 10px;
//  border-radius: 4px;
//  border: 1px solid rgba(0, 0, 255, 0.5);
//}

#next-page-btn {
  color: #5a607f;
  font-size: 14px;
  font-weight: 700 !important;
  border: 1px solid #d7dbec;
}

#next-page-btn:hover {
  color: #0058ff;
  background-color: #ffffff;
  border: 1px solid #0058ff;
}

#next-page-btn:focus {
  color: #a8c6fd;
  background-color: #ffffff;
  border: 1px solid #0058ff;
}

//LOADING SPINNER COLORS

.spinner-white {
  color: #ffffff !important;
}

.spinner-blue {
  color: #0058ff !important;
}

.spinner-red {
  color: #f0142f !important;
}

.spinner-green {
  color: #21d59b !important;
}

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "app/components/_common/form-row-select/form-row-select.component";
@import "app/components/_common/btn/btn.component";
@import "app/pages/product/product.component";
@import "app/components/_common/form-row-checkbox/form-row-checkbox.component";

body {
  font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol;
}

/*REMOVING SCROLL ON X AXES*/
html {
  overflow-x: hidden;
}

/*padding class for all components */
.app-container {
  padding: 20px;
}

body {
  background-color: #F4F7FC;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

/*POSITIONING PAGE SO IT DOES NOT GET BROKEN WHEN REDIRECTED*/

app-login {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1100;
  width: 100vw;
  background-color: #F4F7FC;
}

app-header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

app-side-menu {
  height: 100vh;
  position: fixed;
  z-index: 1035;
}

/*SETTING MARGIN OF TABS IN ORDER PROCESS */
.tab-content>.active {
  margin-left: 0;
}

a {
  text-decoration: none;
}

.card-header {
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, .125);
  padding: .75rem 1.25rem;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.card-title {
  font-size: 18px;
  font-weight: 700;
  color: #1A051D;
  margin-bottom: 0;
}

.card-subheading {
  color: #131523 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

/*MAKING ALL AROUND CORNERS ON NEW ORDER*/
.input-group>.custom-select:not(:first-child),
.input-group>.form-control:not(:first-child) {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

@media only screen and (max-width : 768px) {
  .no-padding-left-md {
    padding-left: 1.5rem;
  }
}

.sidebar-collapse .main-sidebar:not(:hover) .sidebar .nav-item.has-treeview .nav-link i.right.fa.fa-angle-left {
  display: none;
}

.sidebar-collapse .main-sidebar:hover .sidebar .nav-item.has-treeview .nav-link i.right.fa.fa-angle-left {
  display: block;
}

popover-container {
  z-index: 1034 !important;
}

popover-container.note-preview {
  z-index: 1061 !important;
}

/*REMOVING HOVER ON POPOVER TABLE*/
popover-container>div.popover-content.popover-body table>tbody>tr:hover {
  background-color: unset;
}

.btn-group-xs>.btn,
.btn-xs {
  padding: .25rem .4rem;
  font-size: .875rem;
  line-height: .5;
  border-radius: .2rem;
}

.page-tabs .nav-tabs {
  display: none;
}

.categoriesListDndPlaceholder {
  min-height: 56px;
  border: 1px dashed green;
  background-color: rgba(0, 0, 0, 0.1);
}

.dndDraggingSource {
  pointer-events: none;
}

.mh-15rem {
  min-height: 15rem;
}

.tooltip-top {
  display: inline;
  position: relative;
}

.tooltip-top:hover:after {
  background: #333;
  background: rgba(0, 0, 0, .8);
  border-radius: 5px;
  bottom: 16px;
  color: #fff;
  content: attr(data-tooltip);
  left: 30%;
  padding: 10px 15px;
  position: absolute;
  z-index: 999;
  width: 200px;
}

.disabled-product {
  opacity: 0.55;
  text-decoration: line-through;
}

.strikethrough {
  text-decoration: line-through;
}

/* fa input addon same width fix */
.input-group-addon .fa,
.input-group-addon .fal,
.input-group-addon .fas,
.input-group-addon .far {
  width: 2rem;
}

.sidebar-mini.sidebar-collapse .sidebar-menu>li:hover>.treeview-menu {
  width: 100px !important;
  left: 50px !important;
}


@media print {
  @page {
    size: auto;
    margin: 0mm 0mm 0mm 0mm;
  }

  html {
    margin: 0;
    /* ugly hack to print just first page, todo: find better solution */
    position: fixed;
  }

  * {
    color: black !important;
    border: 0 !important;
    box-shadow: none !important;
  }

  .content-wrapper.print-no-margin {
    margin-left: 0 !important;
    min-height: 0 !important;
  }

  .content {
    padding: 0 !important;
  }

  .no-print {
    display: none !important;
  }

  .barcode-array-container {
    border-bottom: 5px solid black;
  }

}

.margin-0-auto {
  margin: 0 auto;
}

.resizable-column {
  position: relative;
}

.resizable-column .resizer {
  width: 1.5rem;
  height: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: col-resize;
  transition: background-color 300ms;
}

.resizable-column .resizer:hover,
.resizable-column .resizer:focus,
.resizable-column .resizer:active {
  background-color: rgba(44, 71, 98, 0.33);
}

.cursor-pointer {
  cursor: pointer;
}

/* AdminLTE fixes (problems are mostly with CSS direct children (using '>' symbol), because Angular renders additional component tags) */

.w-100 {
  width: 100%;
}

.w-49 {
  width: 49%;
}

.margin-right {
  margin-right: 1rem;
}

.margin-left {
  margin-left: 1rem;
}

.margin-left-13-5 {
  margin-left: 13.5rem;
}

.margin-top {
  margin-top: 1rem;
}

.margin-top-sm {
  margin-top: 0.25rem;
}

.margin-top-3 {
  margin-top: 3rem;
}

.margin-top-8-px {
  margin-top: 8px;
}

.margin-bottom {
  margin-bottom: 1rem;
}

.margin-bottom-sm {
  margin-bottom: 0.7rem;
}

.margin-bottom-2 {
  margin-bottom: 2rem;
}

.margin-bottom-3 {
  margin-bottom: 3rem;
}

.mo-margin {
  margin: 0;
}

.btn-sm.margin-right,
.btn-xs.margin-right {
  margin-right: 0.5rem;
}

.padding-right-05 {
  padding-right: 0.5rem;
}

.padding-right {
  padding-right: 1rem;
}

.padding-right-2 {
  padding-right: 2rem;
}

.padding-right-3 {
  padding-right: 3rem;
}

.padding-right-7 {
  padding-right: 7rem;
}

.padding-left {
  padding-left: 1rem;
}

.padding-left-2 {
  padding-left: 2rem !important;
}

.padding-left-3 {
  padding-left: 3rem;
}

.padding-top {
  padding-top: 1rem;
}

.padding-top-sm {
  padding-top: 0.25rem;
}

.padding-bottom {
  padding-bottom: 1rem;
}

.padding-bottom-sm {
  padding-bottom: 0.7rem;
}

.no-padding-left {
  padding-left: 0;
}

.no-padding-left-md {
  padding-left: 0;
}

.no-padding {
  padding: 0;
}

//STYLING UL ON PRODUCT COMPONENT
//STYLING UL ON PRODUCT COMPONENT
//tabset ul {
.tab-container>ul {
  background-color: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 1px 4px #15223214;
  margin-bottom: 20px !important;
  text-decoration: none;
  padding: 5px;
  color: #0058ff;
}

tabset ul {
  background-color: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 1px 4px #15223214;
  margin-bottom: 20px !important;
  text-decoration: none;
  padding: 5px;
}

.sidebar-collapse .submenu:hover {
  display: none;
}

//STYLING FORM GROUP ON ORDER PRODUCTS COMPONENT
body>div.wrapper>app-root>div>app-order>div>section>tabset>div>tab.tab-pane.active>div:nth-child(1)>div.col-12.col-md-9>div:nth-child(1)>div>app-order-products>div>table>tbody tr td:nth-child(6)>form-row-number-with-controls>div {
  margin-bottom: 0;
}

.text-primary {
  color: #0058FF !important;
}

//SETTING BACKGROUND COLOR OF DROPDOWN PANEL OPTION
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-size: 14px;
  color: #5A607F;
  background-color: #FFFFFF;
  font-weight: 500;
}

//SETTING BACKGROUND COLOR OF DROPDOWN SELECTED OPTION
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  font-size: 14px;
  color: #5A607F;
  background-color: #F2F7FF;
  font-weight: 500;
}

//CLASS FOR FIXED MINIMUM COLUMN WIDTH
.fixed-column-width {
  width: 1%;
}

.width-max-content {
  width: max-content;
}

.width-fill-available {
  width: -webkit-fill-available;
}

.width-20rem {
  min-width: 18rem;
  width: 18rem;
  max-width: 18rem;
}

//GLOBAL SETTING FOR ALL TABLES
thead tr th {
  color: #7E84A3;
  font-size: 12px;
  font-weight: 500;
}

tbody {
  font-size: 14px;
  color: #131523;
}

tbody tr td,
thead tr th {
  vertical-align: middle !important;
  padding: 0.4rem !important;
  border-bottom: 1px solid #E6E9F4;
}

.padding-th-checkbox {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.padding-td-checkbox {
  padding: 0.25rem !important;
}

.flex-gap-0-5 {
  display: flex;
  gap: 0.5rem;
}

.flex-gap-5 {
  display: flex;
  gap: 5px;
}

.inline-flex-gap-5 {
  display: inline-flex;
  gap: 5px;
}

.flex-gap-10 {
  display: flex;
  gap: 10px;
}

.flex-gap-20 {
  display: flex;
  gap: 20px;
}

.flex-gap-70 {
  display: flex;
  gap: 70px;
}

.flex-gap-5-reverse {
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
}

.flex-column-gap-10 {
  display: flex;
  column-gap: 10px;
}

.flex-direction-column-gap-5 {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.flex-direction-column-gap-10 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.flex-direction-column-gap-20 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-basis-100 {
  flex-basis: 100%;
}

.h-100px {
  height: 100px;
}

tbody tr td {
  padding-top: 10px;
  padding-bottom: 10px;
}

//GLOBAL HOVER ON ALL TABLES
tbody tr:hover td {
  background-color: #F4F7FC;
}

td.no-hover {
  background-color: unset !important;
}

//CHANGING BACKGROUND COLOR OF BTN WHEN TR IS HOVERED
//tbody tr:hover .btn-second {
//  background-color: #ffffff;
//}

//STYLING NUMBER INPUTS
.input-unit input {
  padding-right: 5px;
  padding-left: 24px;
  text-align: right;
}

.price-align input {
  text-align: right;
}

input[type=number] {
  text-align: right;
}

.input-dash {
  display: flex;
  gap: 15px;
}

//STYLING LINK
.link {
  color: #0058FF;
  border-bottom: 1px solid transparent;
}

.link:hover {
  color: #0047CC;
  border-bottom: 1px solid #0047CC;
}

.link:focus {
  color: #A8C6FD;
  border-bottom: 1px solid #A8C6FD;
}

.link:disabled {
  color: #D7DBEC;
  border-bottom: 1px solid #D7DBEC;
}


.link-bold {
  color: #0058FF;
  font-weight: 600;
  border-bottom: 1px solid transparent;
}

.link-bold:hover {
  color: #0047CC;
  border-bottom: 1px solid #0047CC;
}

.link-bold:focus {
  color: #A8C6FD;
  border-bottom: 1px solid #A8C6FD;
}

.link-bold:disabled {
  color: #D7DBEC;
  border-bottom: 1px solid #D7DBEC;
}

.product-details {
  color: #7E84A3;
  font-size: 12px;
  font-weight: 500;
}

.note {
  color: #7E84A3;
  font-size: 12px;
  font-weight: 400;
}

//ALLOWING TO CLICK ON SIDE MENU WHEN IT IS IN MOBILE VERSION
#sidebar-overlay {
  background-color: #0000001a;
  display: none;
  inset: 0;
  position: fixed;
  z-index: 1034 !important;
}

@media (max-width: 991px) {
  .sidebar-open #sidebar-overlay {
    display: block;
  }
}

/*ANIMATING ROWS SHOW*/
div.custom-dropdown {
  max-height: 0;
  overflow: hidden;
  transition: max-height 300ms ease-in-out;
}

div.custom-dropdown.open {
  overflow: visible;
  max-height: 1000px;
  animation: hide-scroll 300ms backwards;
}

@keyframes hide-scroll {

  from,
  to {
    overflow: hidden;
  }
}

//AVATAR ICON
.circle {
  background-color: #0058FF;
  border-radius: 50%;
  text-align: center;
  height: 50px !important;
  width: 50px !important;
  display: flex;
  justify-content: center;
}

.initials {
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  align-self: center;
}

.avatar-wrapper {
  font-size: 16px;
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.avatar-wrapper>div {
  align-self: center;
}

//STYLING PORDUCT IMAGE
.images-item {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #D7DBEC;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: box-shadow 0.15s ease-in-out;
}

.images-item:hover {
  box-shadow: 0 0 0 2px rgb(168 198 253);
  cursor: pointer;
}

.images-item .images-item-image {
  width: 55px;
  height: auto;
}

//CK EDITOR DEFAULT HEIGHT
.ck-editor__editable_inline {
  min-height: 120px;
}

//TYP BADGES
.in-badge {
  color: #21D59B;
  font-size: 16px;
}

.out-badge {
  color: #F0142F;
  font-size: 16px;
}

.transfer-badge {
  color: #F99600;
  font-size: 16px;
}

.reservation-badge {
  color: #0058FF;
  font-size: 16px;
}

.badge-second {
  color: #0058FF;
  background-color: #E5EFFE;
}

.badge {
  &.bg-light {
    color: #1f2d3d;
  }
}

.bg-danger {
  color: #fff;
}

.overlay {
  position: absolute;
  display: flex;
  align-items: center !important;
  justify-content: flex-start !important;
  flex-direction: column;
  width: calc(100% - 2rem);
  //width: 100%;
  height: 100%;
  opacity: 0.5 !important;
  transition: all 300ms;
  z-index: 99999999;
  cursor: progress;
}

.h150 {
  min-height: 150px !important;
  max-height: 150px !important;
  height: 150px !important;
}

.empty-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 14px;
  color: #7E84A3;
}

.empty-list>i {
  font-size: 18px;
}

.box-border {
  border: 1px solid #E3E6F1;
  border-radius: 6px;
  padding: 20px;
}

.box-border-border {
  border-bottom: 1px solid #E6E9F4;
  padding-bottom: 10px;
}

select.form-control {
  appearance: auto;
}

input.form-control {
  border: 1px solid #F3F3F4;
  background-color: #F3F3F4;

  transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

input.form-control:hover {
  border: 1px solid #F3F3F4;
  box-shadow: 0 0 0 2px rgb(168 198 253 / 50%);
  background-color: #FFFFFF;
  cursor: text;
}

input.form-control:focus {
  border: 1px solid #0058FF;
  box-shadow: 0 0 0 2px rgb(0 88 255 / 50%);
  background-color: #FFFFFF;
}

input.form-control:disabled {
  border: 1px solid #F3F3F4 !important;
  color: #999999 !important;
  background-color: #FAFAFA !important;
  box-shadow: unset !important;
  cursor: not-allowed !important;
}

select.form-control {
  background-color: #F3F3F4;
  border: 1px solid #F3F3F4;
  border-radius: 4px !important;

  transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

select.form-control:hover {
  background-color: #FFFFFF;
  border: 1px solid #D7DBEC;
  box-shadow: 0 0 0 2px rgb(168 198 253 / 50%);
  cursor: pointer;
}

select.form-control:focus {
  background-color: #FFFFFF;
  border: 1px solid #0058FF !important;
  box-shadow: 0 0 0 2px rgb(168 198 253 / 50%) !important;
}

select.form-control:disabled {
  background-color: #FAFAFA;
  border: 1px solid #F3F3F4;
  color: #999999;
  cursor: not-allowed;
  box-shadow: unset;
}

select.form-control.is-invalid {
  border-color: #F0142F;
}

select.form-control.is-invalid:hover {
  border-color: #F0142F;
  box-shadow: 0 0 0 2px rgb(240 20 47 / 50%);
}

select.form-control.is-invalid:focus {
  border-color: #F0142F;
  box-shadow: 0 0 0 2px rgb(240 20 47 / 50%);
}

/*STYLING NG SELECT*/

.ng-select .ng-select-container {
  border: 1px solid #F3F3F4;
  border-radius: 4px;
  background-color: #F3F3F4;

  transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out !important;
}

.ng-select .ng-select-container:hover {
  background-color: #FFFFFF;
  border: 1px solid #D7DBEC;
  box-shadow: 0 0 0 2px rgb(168 198 253 / 50%);
  cursor: pointer;
}

.ng-select .ng-select-container:hover .ng-arrow-wrapper {
  background-color: transparent;
}

.ng-select.ng-select-opened>.ng-select-container {
  background-color: #FFFFFF;
  border: 1px solid #0058FF !important;
  box-shadow: 0 0 0 2px rgb(168 198 253 / 50%) !important;
}

span.ng-arrow-wrapper {
  background-color: transparent;
}

.ng-select .ng-select-container:disabled {
  background-color: #FAFAFA;
  border: 1px solid #F3F3F4;
  color: #999999;
  cursor: not-allowed;
  box-shadow: unset;
}

ng-dropdown-panel.ng-dropdown-panel {
  border: 1px solid #D7DBEC;
  box-shadow: 0 3px 6px #00000029;
}

textarea.form-control {
  background-color: #F3F3F4;
  border: 1px solid #F3F3F4;
  height: auto;

  transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

textarea.form-control:hover {
  background-color: #FFFFFF;
  border: 1px solid #D7DBEC;
  box-shadow: 0 0 0 2px rgb(168 198 253 / 50%);
  cursor: text;
}

textarea.form-control:focus {
  background-color: #FFFFFF !important;
  border: 1px solid #0058FF !important;
  box-shadow: 0 0 0 2px rgb(168 198 253 / 50%) !important;
}

textarea.form-control:disabled {
  background-color: #FAFAFA;
  border: 1px solid #F3F3F4;
  color: #999999;
  cursor: not-allowed;
  box-shadow: unset;
}

//TODO: JD
//div.input-group input.form-control {
//  border-radius: 4px !important;
//}

input.form-control.is_pristine {
  //border: 1px solid #D7DBEC;
  background-color: #F3F3F4;
}

input.form-control.is_pristine:hover {
  border: 1px solid #D7DBEC;
  box-shadow: 0 0 0 2px rgb(168 198 253 / 50%);
  background-color: #FFFFFF;
}

input.form-control.is_pristine:focus {
  border: 1px solid #0058FF;
  box-shadow: 0 0 0 2px rgb(0 88 255 / 50%);
  background-color: #FFFFFF;
}

input.form-control.is-invalid {
  border-color: #F0142F;
}

input.form-control.is-invalid:hover {
  border-color: #F0142F;
  box-shadow: 0 0 0 2px rgb(240 20 47 / 50%);
}

input.form-control.is-invalid:focus {
  border-color: #F0142F;
  box-shadow: 0 0 0 2px rgb(240 20 47 / 50%);
}

//BELLOW IS FIX FOR ANGULAR/POPOVER OVERFLOW OUT OF SCREEN
@media (max-width: 1281px) {
  div.popover-content.popover-body {
    max-height: 600px;
    overflow-y: auto;
  }
}

//POSITIONING MODAL MODAL STOCK OVERVIEW IN MODAL PRODUCT CHOOSE
body>div.wrapper>app-root>div>app-order>div>section>tabset>div>tab.tab-pane.active>div>div.col-12.col-md-12.col-lg-12.col-xl-8>div:nth-child(1)>div>app-order-products>div>div.row>div.col-12.col-md-9>modal-product-choose>modal-container>div>div>div>div.modal-body>div:nth-child(3) div table tbody tr td modal-stock-overview modal-container div.modal-dialog.modal-xl.modal-center {
  margin-right: 10%;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
}

//PHOTOSWIPE ARROWS
button.pswp__button--arrow--right:before {
  background-position: -90px -40px !important;
  background-color: black !important;
  width: 38px !important;
  height: 38px !important;
}

button.pswp__button.pswp__button--arrow--right {
  right: 18px !important;
}

button.pswp__button--arrow--left:before {
  left: 6px !important;
  background-position: -136px -40px !important;
  background-color: black !important;
  width: 38px !important;
  height: 38px !important;
}

//SETTING ALL FIRST TD IN EVERY TR IN VOUCHERS MAX WIDTH
body>div.wrapper>app-root>div>app-vouchers>div>section>app-base-listing>div table tbody tr td:first-of-type {
  max-width: 300px;
  overflow-x: auto;
}

//ng-snotify
div.snotify.snotify-centerTop {
  min-width: 900px;
  left: calc(50% - 900px / 2);
}

div.snotifyToast.snotify-success {
  background-color: #E8FBF5;
}

div.snotifyToast.snotify-success div.snotifyToast__body {
  color: #21D59B;
  font-weight: 600;
}

div.snotifyToast.animated.snotify-success.fadeIn div.snotifyToast__buttons button.snotifyToast__buttons--bold {
  color: #21D59B;
  text-transform: unset;
}

div.snotifyToast.snotify-error {
  background-color: #FEE7E9;
}

div.snotifyToast.snotify-error div.snotifyToast__body {
  color: #F0142F;
  font-weight: 600;
}

div.snotifyToast.animated.snotify-error.fadeIn div.snotifyToast__buttons button.snotifyToast__buttons--bold {
  color: #F0142F;
  text-transform: unset;
}

div.snotifyToast.snotify-info {
  background-color: #EEF8FF;
}

div.snotifyToast.snotify-info div.snotifyToast__body {
  color: #57B8FF;
  font-weight: 600;
}

div.snotifyToast.animated.snotify-info.fadeIn div.snotifyToast__buttons button.snotifyToast__buttons--bold {
  color: #57B8FF;
  text-transform: unset;
}

div.snotifyToast.snotify-warning {
  background-color: #FFF4E5;
}

div.snotifyToast.snotify-warning div.snotifyToast__body {
  color: #F99600;
  font-weight: 600;
}

div.snotifyToast.animated.snotify-warning.fadeIn div.snotifyToast__buttons button.snotifyToast__buttons--bold {
  color: #F99600;
  text-transform: unset;
}


div.snotify-icon {
  right: unset;
}

div.snotifyToast__body {
  margin-left: 63px;
}

div.snotify-icon {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  box-sizing: border-box;
  border-radius: 10px;
  max-height: 50px;
  max-width: 50px;
}

div.snotifyToast.animated.fadeIn div.snotifyToast__buttons {
  position: absolute;
  left: 860px;
  border-radius: 50%;
  height: 20px;
  top: 15px;
  width: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-width: 0;
}

button.snotifyToast__buttons button:focus,
.snotifyToast__buttons button:hover {
  background: unset !important;
  outline: unset !important;
  cursor: pointer;
}

div.snotify-icon--success {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJWcnN0dmFfMSIgZm9jdXNhYmxlPSJmYWxzZSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjogIzIxRDU5QjsiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KICA8cGF0aCBjbGFzcz0ic3QwIiBkPSJNIDQzNy41NjggMjYwLjEwMyBDIDQzNy41NjggMzU4LjI0MyAzNTMuMzc0IDQzNy43ODMgMjQ5LjQ5MSA0MzcuNzgzIEMgMTQ1LjYwNiA0MzcuNzgzIDYxLjQxMSAzNTguMjQzIDYxLjQxMSAyNjAuMTAzIEMgNjEuNDExIDE2MS45NjEgMTQ1LjYwNiA4Mi40MjMgMjQ5LjQ5MSA4Mi40MjMgQyAzNTMuMzc0IDgyLjQyMyA0MzcuNTY4IDE2MS45NjEgNDM3LjU2OCAyNjAuMTAzIFogTSAyMjcuNzQ0IDM1NC4xNDggTCAzNjcuMjYgMjIyLjM0NiBDIDM3MS45NjEgMjE3LjkwNCAzNzEuOTYxIDIxMC42MTYgMzY3LjI2IDIwNi4xMDUgTCAzNTAuMTQxIDE4OS45MzMgQyAzNDUuNDM5IDE4NS40MjIgMzM3LjcyNiAxODUuNDIyIDMzMi45NSAxODkuOTMzIEwgMjE5LjE0OCAyOTcuNDQzIEwgMTY2LjAzIDI0Ny4yNjMgQyAxNjEuMzI4IDI0Mi44MiAxNTMuNjE1IDI0Mi44MiAxNDguODM5IDI0Ny4yNjMgTCAxMzEuNjQ4IDI2My41MDQgQyAxMjYuOTQ2IDI2Ny45NDUgMTI2Ljk0NiAyNzUuMjMzIDEzMS42NDggMjc5Ljc0NSBMIDIxMC41NTIgMzU0LjE0OCBDIDIxNS4zMjggMzU4LjY1OSAyMjIuOTY5IDM1OC42NTkgMjI3Ljc0NCAzNTQuMTQ4IEwgMjI3Ljc0NCAzNTQuMTQ4IFoiIHN0eWxlPSIiLz4KPC9zdmc+");
}

div.snotify-icon--error {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJWcnN0dmFfMSIgZm9jdXNhYmxlPSJmYWxzZSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA1NzYgNTEyIiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjogI0YwMTQyRjsiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KICA8cGF0aCBjbGFzcz0ic3QwIiBkPSJNIDQ4MC4xMjIgMzY3LjY3NSBDIDQ5Mi41MTMgMzg4LjMxMyA0NzYuOTc0IDQxNC4xMTIgNDUyLjI1OSA0MTQuMTEyIEwgMTMwLjg5OSA0MTQuMTEyIEMgMTA2LjE4NSA0MTQuMTEyIDkwLjcxMyAzODguMjQ5IDEwMy4wMzcgMzY3LjY3NSBMIDI2My43MTcgOTkuMzY4IEMgMjc2LjEwNyA3OC43MjkgMzA3LjA1MSA3OC43MjkgMzE5LjQ0MiA5OS4zNjggTCA0ODAuMTIyIDM2Ny42NzUgTCA0ODAuMTIyIDM2Ny42NzUgWiBNIDI5MS41NzkgMzEyLjIwOCBDIDI3NC41NjcgMzEyLjIwOCAyNjAuNzcgMzI1LjQ5MyAyNjAuNzcgMzQxLjg3NiBDIDI2MC43NyAzNTguMjU4IDI3NC41NjcgMzcxLjU0NCAyOTEuNTc5IDM3MS41NDQgQyAzMDguNTkyIDM3MS41NDQgMzIyLjM4OSAzNTguMjU4IDMyMi4zODkgMzQxLjg3NiBDIDMyMi4zODkgMzI1LjQ5MyAzMDguNTkyIDMxMi4yMDggMjkxLjU3OSAzMTIuMjA4IFogTSAyNjIuMzEgMjA1LjU5NCBMIDI2Ny4yNjYgMjkzLjMxIEMgMjY3LjQ2NyAyOTcuNDM3IDI3MS4wMTcgMzAwLjU5OCAyNzUuMzAzIDMwMC41OTggTCAzMDcuNzg4IDMwMC41OTggQyAzMTIuMDc1IDMwMC41OTggMzE1LjU1NyAyOTcuMzczIDMxNS44MjUgMjkzLjMxIEwgMzIwLjc4MiAyMDUuNTk0IEMgMzIxLjA0OSAyMDEuMTQ0IDMxNy4zNjUgMTk3LjQwMyAzMTIuNzQ1IDE5Ny40MDMgTCAyNzAuMjggMTk3LjQwMyBDIDI2NS43MjUgMTk3LjQwMyAyNjIuMTA5IDIwMS4xNDQgMjYyLjMxIDIwNS41OTQgTCAyNjIuMzEgMjA1LjU5NCBaIiBzdHlsZT0iIi8+Cjwvc3ZnPg==");
}

div.snotify-icon--info {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJWcnN0dmFfMSIgZm9jdXNhYmxlPSJmYWxzZSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjogIzU3QjhGRjsiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KICA8cGF0aCBjbGFzcz0ic3QwIiBkPSJNIDI1Ni4zOTIgOTMuODA1IEMgMTU4LjIwMyA5My44MDUgNzguNjIyIDE3MS42MTggNzguNjIyIDI2Ny40NzYgQyA3OC42MjIgMzYzLjQwMiAxNTguMjAzIDQ0MS4xNDggMjU2LjM5MiA0NDEuMTQ4IEMgMzU0LjU4MSA0NDEuMTQ4IDQzNC4xNjIgMzYzLjQwMiA0MzQuMTYyIDI2Ny40NzYgQyA0MzQuMTYyIDE3MS42MTggMzU0LjU4MSA5My44MDUgMjU2LjM5MiA5My44MDUgWiBNIDI1Ni4zOTIgMTcwLjgwNSBDIDI3Mi45ODkgMTcwLjgwNSAyODYuNTMgMTgzLjk2NCAyODYuNTMgMjAwLjI0NyBDIDI4Ni41MyAyMTYuNTI5IDI3My4wNTcgMjI5LjY4OSAyNTYuMzkyIDIyOS42ODkgQyAyMzkuNzI2IDIyOS42ODkgMjI2LjI1NCAyMTYuNTI5IDIyNi4yNTQgMjAwLjI0NyBDIDIyNi4yNTQgMTgzLjk2NCAyMzkuNzk1IDE3MC44MDUgMjU2LjM5MiAxNzAuODA1IFogTSAyOTYuNTI4IDM0OC42ODEgQyAyOTYuNTI4IDM1My4yOTQgMjkyLjcxIDM1Ny4wOTMgMjg3LjkxOCAzNTcuMDkzIEwgMjI0Ljg2NiAzNTcuMDkzIEMgMjIwLjE0NCAzNTcuMDkzIDIxNi4yNTYgMzUzLjM2MiAyMTYuMjU2IDM0OC42ODEgTCAyMTYuMjU2IDMzMS45MjUgQyAyMTYuMjU2IDMyNy4zMTIgMjIwLjA3NCAzMjMuNTEyIDIyNC44NjYgMzIzLjUxMiBMIDIzMy40NzcgMzIzLjUxMiBMIDIzMy40NzcgMjc4LjY3IEwgMjI0Ljg2NiAyNzguNjcgQyAyMjAuMTQ0IDI3OC42NyAyMTYuMjU2IDI3NC45MzkgMjE2LjI1NiAyNzAuMjU4IEwgMjE2LjI1NiAyNTMuNDMzIEMgMjE2LjI1NiAyNDguODIxIDIyMC4wNzQgMjQ1LjAyMSAyMjQuODY2IDI0NS4wMjEgTCAyNzAuNzY2IDI0NS4wMjEgQyAyNzUuNDg5IDI0NS4wMjEgMjc5LjM3NyAyNDguNzUzIDI3OS4zNzcgMjUzLjQzMyBMIDI3OS4zNzcgMzIzLjQ0NCBMIDI4Ny45ODggMzIzLjQ0NCBDIDI5Mi43MSAzMjMuNDQ0IDI5Ni41OTkgMzI3LjE3NiAyOTYuNTk5IDMzMS44NTcgTCAyOTYuNTk5IDM0OC42ODEgWiIgc3R5bGU9IiIvPgo8L3N2Zz4=");
}

div.snotify-icon--warning {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJWcnN0dmFfMSIgZm9jdXNhYmxlPSJmYWxzZSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA0NDggNTEyIiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjogI0Y5OTYwMDsiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KICA8cGF0aCBjbGFzcz0ic3QwIiBkPSJNIDIxOC41ODUgNDMwLjE4MyBDIDI0Mi4zNjIgNDMwLjE4MyAyNjEuNjk0IDQxMS41MjggMjYxLjY5NCAzODguNDM3IEwgMTc1LjQ3OSAzODguNDM3IEMgMTc1LjQ3OSA0MTEuNTI4IDE5NC44MTEgNDMwLjE4MyAyMTguNTg1IDQzMC4xODMgWiBNIDM2My42NjcgMzMyLjUzNyBDIDM1MC42NjggMzE4Ljk3MiAzMjYuMjg2IDI5OC42MiAzMjYuMjg2IDIzMS44OTQgQyAzMjYuMjg2IDE4MS4yMTMgMjg5LjU3NyAxNDAuNjQyIDI0MC4xMzkgMTMwLjY2NCBMIDI0MC4xMzkgMTE3LjA5NiBDIDI0MC4xMzkgMTA1LjU1IDIzMC41MDcgOTYuMjIzIDIxOC41ODUgOTYuMjIzIEMgMjA2LjY2MyA5Ni4yMjMgMTk3LjAzMiAxMDUuNTUgMTk3LjAzMiAxMTcuMDk2IEwgMTk3LjAzMiAxMzAuNjY0IEMgMTQ3LjU5NCAxNDAuNjQyIDExMC44ODcgMTgxLjIxMyAxMTAuODg3IDIzMS44OTQgQyAxMTAuODg3IDI5OC42MiA4Ni41MDQgMzE4Ljk3MiA3My41MDQgMzMyLjUzNyBDIDY5LjQ2MiAzMzYuNzEzIDY3LjcxMiAzNDEuNzM1IDY3LjcxMiAzNDYuNjkyIEMgNjcuNzc5IDM1Ny4zOSA3Ni40NjggMzY3LjU2NSA4OS4zMzMgMzY3LjU2NSBMIDM0Ny44NCAzNjcuNTY1IEMgMzYwLjcwMyAzNjcuNTY1IDM2OS4zOTIgMzU3LjM5IDM2OS40NiAzNDYuNjkyIEMgMzY5LjQ2IDM0MS43MzUgMzY3LjcwOCAzMzYuNzEzIDM2My42NjcgMzMyLjUzNyBaIiBzdHlsZT0iIi8+Cjwvc3ZnPg==");
}

//FIX OTEVIRANI SIDE MENU KDYZ JE OTEVRENY MODAL
div.modal.fade.come-from-modal.right.in.show {
  left: unset;
}

.availability-icons {
  display: flex;
  justify-content: space-around;
  padding: 16px 20px;
  background-color: #fafafa;
  border: 1px solid #e8ebf5;
  border-radius: 6px;
  opacity: 1;
  font-size: 14px;
  gap: 5px;
}

.availability-icons .flex-gap-5.align-items-center:nth-of-type(1) {
  color: #0058ff;
}

.availability-icons .flex-gap-5.align-items-center:nth-of-type(2) {
  color: #f0142f;
}

.availability-icons .flex-gap-5.align-items-center:nth-of-type(3) {
  color: #21d59b;
}

.availability-icons .flex-gap-5.align-items-center:nth-of-type(4) {
  color: #F99600;
}

.stock-positions {
  border: 1px solid #E6E9F4;
  border-radius: 6px;
  margin-left: 0;
  margin-right: 0;
  padding: 15px;
  margin-top: 15px;
}

.stock-positions-absolute {
  position: absolute;
  top: -33px;
  left: 10px;
  color: #7E84A3;
  background-color: white;
  padding: 5px;
  font-weight: 600;
  white-space: nowrap;
}

.required {
  color: #aaa !important;
}

div.modal-content {
  border: unset;
}

alert div.alert.alert-warning {
  margin-bottom: 0;
}

.tag-span {
  font-size: 12px;
  padding: 2px 7px;
  margin-right: 3px;
  margin-bottom: 2px;
  border-radius: 50vh;
  align-self: flex-start;
}

.container-tags {
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  width: auto;
}

.justify-content-left {
  justify-content: left;
}

.justify-content-right {
  justify-content: right;
}

.align-items-center {
  align-items: center;
}

.text-align-center {
  text-align: center;
}

.rolling-content-btn {
  color: #0058ff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
}

.rotate {
  transform: rotate(180deg);
  transition: transform 300ms linear;
}

.label-bold {
  font-weight: 600;
  font-size: 14px !important;
}

.status-new {
  color: #F99600;
  background-color: #ffeacb;
  border: 1px solid #ffd597;
}

.status-partly_stocked {
  color: #FCC703;
  background-color: #fff7d9;
  border: 1px solid #fee99a;
}

.status-stocked {
  color: #3DD59B;
  background: #e2f9f0;
  border: 1px solid #b1eed7;
}

.margin-top-1-2 {
  margin-top: 1.2rem;
}

.card [class^="fa fa-file"] {
  padding-right: .5rem;
  font-size: 18px;
}

.fa-trash-alt {
  font-size: 15px
}


.wrapper {
  position: relative;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -7.5px;
  margin-left: -7.5px;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  padding-left: 7.5px;
  padding-right: 7.5px;
  position: relative;
}

.content-wrapper {
  height: 100%;
  background-color: #f4f6f9;
  transition: margin-left .3s ease-in-out;

  @media(min-width: 992px) {
    margin-left: 250px;
  }
}



label:not(.form-check-label):not(.custom-file-label) {
  font-weight: 600;
  font-size: 12px;
  color: #131523;
  margin-bottom: 0;
}

.form-group {
  margin-bottom: 8px;
}


.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, .125);
  box-shadow: 0 1px 4px #15223214;
  padding: 20px;
  border-radius: 6px;
  margin-bottom: 20px;
}


.table {
  thead {
    th {
      padding: .75rem;
      padding-left: 0.75rem;
      vertical-align: top;
      border-top: 0;
      border-bottom: 1px solid #e6e9f4;
    }
  }

  th {
    vertical-align: bottom;
  }

  td,
  th {
    padding: .75rem;
    padding-left: 0.75rem;
    vertical-align: top;
    border-top: 0;
    border-bottom: 1px solid #e6e9f4;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #0058ff;
  background-color: #f2f7ff;
}


@media (min-width: 992px) {

  .sidebar-mini.sidebar-collapse .main-sidebar,
  .sidebar-mini.sidebar-collapse .main-sidebar:before {
    margin-left: 0;
    width: 4.6rem;
    overflow-x: hidden;
  }

  .sidebar-mini.sidebar-collapse .content-wrapper,
  .sidebar-mini.sidebar-collapse .main-footer,
  .sidebar-mini.sidebar-collapse .main-header {
    margin-left: 4.6rem !important;
  }
}

.sidebar-mini.sidebar-collapse {
  .logo-xs {
    opacity: 1;
    visibility: visible;
    transition: all 0.3s;
  }

  .logo-xl {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
}

.main-sidebar .sidebar {
  overflow-y: auto;
}

@media(min-width: 991px) {

  .sidebar-mini.sidebar-collapse .sidebar .user-panel>.info,
  .sidebar-mini.sidebar-collapse .nav-sidebar .nav-link p,
  .sidebar-mini.sidebar-collapse .brand-text {
    visibility: hidden !important;
    white-space: nowrap !important;
  }
}

.sidebar-collapse.sidebar-mini .main-sidebar .sidebar,
.sidebar-collapse.sidebar-mini-md .main-sidebar .sidebar,
.sidebar-collapse.sidebar-mini-xs .main-sidebar .sidebar {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none;
}

.dropdown {
  .dropdown-menu-right {
    right: 0;
    left: auto;
  }
}

.float-right {
  float: right;
}

.sidebar-mini.sidebar-collapse .main-sidebar:hover {
  width: 270px;
  overflow-x: hidden;
  height: inherit;
  min-height: 100%;
  position: absolute;
  top: 0;

  .logo-xs {
    opacity: 0;
    visibility: hidden;
  }

  .logo-xl {
    opacity: 1;
    visibility: visible;
  }

  .sidebar .user-panel>.info,
  .nav-sidebar .nav-link p,
  .brand-text {
    visibility: visible !important;
  }
}

@media (max-width: 991px) {
  .main-sidebar {
    transition: margin-left .3s ease-in-out, width .3s ease-in-out;
  }

  .sidebar-mini.sidebar-collapse .main-sidebar {
    box-shadow: none !important;
    margin-left: -250px;
  }
}

.table {
  thead {
    tr {
      th {
        color: rgb(126, 132, 163);
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}

.bg-primary, .bg-primary > a {
  color: #fff;
}

.popover {
  max-width: unset;
  box-shadow: 0 .25rem .5rem #0003;
}
.popover-body {
  max-width: unset;
}

@media (min-width: 992px) {
  .modal-product-choose {
    max-width: 1200px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1200px) {
  .modal-product-choose{
    max-width: 1540px;
  }
}


.checkbox-in-table {
  &.form-check {
    font-size: 16px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    .form-check-input {
      margin-left: 0;
      margin-top: 0px;
    }
  }
}


.sidebar-collapse {
  .nav-link-sub {
    transition: all 0.3s;
  }
  .main-sidebar:not(:hover) {
    .nav-link-sub {
      padding-left: 24px !important;
    }
    a.nav-link-sub.active-submenu {
      padding-left: 20px !important;
    }
  }
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 1rem;
    padding-left: 1rem;
    color: #00000080;
}

label:not(.form-check-label):not(.custom-file-label) {
  font-weight: 600;
}

.form-control {
  font-size: 14px;
  height: calc(2.25rem + 2px);
  font-weight: 400;
}

.content-header h1 {
  margin-bottom: 0;
}

.btn-block {
  width: 100%;
  display: block;
}

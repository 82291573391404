label.form-check-label {
  font-size: 14px !important;
}

.form-check-label {
  line-height: 24px;
}

div.flex-gap-5 > div.custom-control.custom-checkbox input[type='checkbox']:hover + label {
  cursor: pointer;
}

div.flex-gap-5 > div.custom-control.custom-checkbox input[type='checkbox']:disabled + label {
  cursor: not-allowed;
}

//input[type="checkbox"] {
//  width: 20px;
//  height: 20px;
//}
//
//input[type="checkbox"]:hover {
//  box-shadow: 0 0 0 2px rgb(168 198 253 / 50%);
//  cursor: pointer;
//}
//
//label {
//  cursor: pointer;
//}
//
//input[type="checkbox"]:checked {
//  box-shadow: 0 0 0 2px rgb(168 198 253 / 50%);
//  cursor: pointer;
//}

.checkbox-bulk-edits {
  height: 1rem;
  margin: 1px;
}

.checkbox-in-table {
  padding-left: 0 !important;
}

.label-checkbox-in-table::before {
  left: -1rem !important;
}

.label-checkbox-in-table::after {
  left: -1rem !important;
}

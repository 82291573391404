.nav-pills .nav-link:hover {
  background-color: #f2f7ff;
}

body > div.wrapper > app-root > div > app-product > div > section > tabset > .nav-pills {
  padding: 8px;
}

li.nav-item.product-tab-heading {
  font-size: 14px;
  font-weight: 600;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 6px;
}

li.nav-item.product-tab-heading.active {
  background-color: #f2f7ff;
  color: #0058ff;
}

li.nav-item.product-tab-heading.active a span {
  color: #0058ff;
}

li.nav-item.product-tab-heading:hover {
  background-color: #f2f7ff;
  color: #0058ff;
}
li.nav-item.product-tab-heading:hover a span {
  color: #0058ff;
}

li.nav-item.product-tab-heading a {
  color: #1a051d;
  font-weight: 600 !important;
  font-size: 14px !important;
}

li.nav-item.product-tab-heading a:hover,
li.nav-item.product-tab-heading a.active{
  color: #0058ff !important;
}

li.nav-item.product-tab-heading.disabled {
  width: 1px !important;
  height: 1px !important;
  visibility: hidden !important;
  margin-left: -1px;
}
